var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Drawer",
        {
          attrs: {
            "wrap-class-name": "detaildrawer",
            closable: false,
            visible: _vm.visible,
            "show-confirm": false,
            size: "large",
            "z-index": 2,
          },
          on: { close: _vm.onClose },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              { staticClass: "userInfo", attrs: { id: "userInfo" } },
              [
                _c(
                  "a-row",
                  {
                    attrs: { type: "flex", justify: "start", align: "middle" },
                  },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 2 } },
                      [
                        _c("CbAvater", {
                          attrs: {
                            "on-job-id": _vm.billDetailParam.onJobId,
                            "avater-name": _vm.insureBillDetail.staffName,
                            size: "52",
                            "show-user-name": false,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("a-col", { attrs: { span: 9 } }, [
                      _c("p", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.insureBillDetail.staffName)),
                      ]),
                      _vm.detailType == "company"
                        ? _c("p", { staticClass: "companyName" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.insureBillDetail.insureClassifyDetails
                                    .length > 0
                                    ? _vm.insureBillDetail
                                        .insureClassifyDetails[0].companyName
                                    : ""
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "money" }, [
                        _c("p", { staticClass: "total" }, [
                          _vm._v(
                            " 总计：" +
                              _vm._s(
                                _vm._f("stateFormatNum")(
                                  (
                                    Number(
                                      _vm.companyTotal(
                                        _vm.insureBillDetail
                                          .insureClassifyDetails
                                      )
                                    ) +
                                    Number(
                                      _vm.personTotal(
                                        _vm.insureBillDetail
                                          .insureClassifyDetails
                                      )
                                    )
                                  ).toFixed(2)
                                )
                              ) +
                              "元 "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            " 本月公司合计：" +
                              _vm._s(
                                _vm._f("stateFormatNum")(
                                  Number(
                                    _vm.companyTotal(
                                      _vm.insureBillDetail.insureClassifyDetails
                                    )
                                  ).toFixed(2)
                                )
                              ) +
                              "元；本月个人合计：" +
                              _vm._s(
                                _vm._f("stateFormatNum")(
                                  Number(
                                    _vm.personTotal(
                                      _vm.insureBillDetail.insureClassifyDetails
                                    )
                                  ).toFixed(2)
                                )
                              ) +
                              "元 "
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "detail beauty-scroll" },
              [
                _vm._l(
                  _vm.insureBillDetail.insureClassifyDetails,
                  function (item, index) {
                    return [
                      _vm.detailType == "person"
                        ? _c("p", { key: "index" + index }, [
                            _vm._v(_vm._s(item.companyName)),
                          ])
                        : _vm._e(),
                      _c("DetailTable", {
                        key: index,
                        attrs: {
                          "data-form":
                            _vm.insureBillDetail.insureClassifyDetails[index],
                          "edit-flag": _vm.editFlag,
                          "show-flag": _vm.showFlag,
                        },
                        on: {
                          "update:dataForm": function ($event) {
                            return _vm.$set(
                              _vm.insureBillDetail.insureClassifyDetails,
                              index,
                              $event
                            )
                          },
                          "update:data-form": function ($event) {
                            return _vm.$set(
                              _vm.insureBillDetail.insureClassifyDetails,
                              index,
                              $event
                            )
                          },
                        },
                      }),
                    ]
                  }
                ),
              ],
              2
            ),
          ]),
        ]
      ),
      _c(
        "Modal",
        {
          attrs: { visible: _vm.deleteVisible, title: "删除补缴调差" },
          on: {
            cancel: function ($event) {
              _vm.deleteVisible = false
            },
            ok: _vm.deletedOk,
          },
        },
        [_c("p", [_vm._v("确定要删除补缴调差吗？")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }